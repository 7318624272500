.profileContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.avatar {
    width: 250px;
    border-radius: 5%;
}

.descriptionLabel {
    white-space: nowrap;
    width: auto; /* Метка будет ограничена содержимым */
    min-width: 150px; /* Минимальная ширина для единообразного выравнивания */
    text-align: left;
}

.descriptionContent {
    text-align: left;
}

.contactField {
    margin-bottom: 10px;
}

