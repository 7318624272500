@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;300;900;700&display=swap');

.header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #001529;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.logo {
    width: 40px;
    border-radius: 7px;
    height: auto;
    margin-right: 20px;
}

.menu {
    list-style: none;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
    font-weight: 300;
    width: auto; /* Или укажите фиксированную ширину, если требуется */
    white-space: nowrap;
}

.menuContainer {
    flex-grow: 1;
}

.loginBlock {
    margin-left: auto;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
}

.loginButton {
    border: none;
    background-color: dodgerblue;
    border-radius: 999px;
    margin-left: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    padding: 8px 15px;
}

.logoutButton {
    border: none;
    background-color: slateblue;
    border-radius: 999px;
    margin-right: 12px;
    letter-spacing: 0.6px;
    padding: 8px 15px;
}