.postsBlock {
    padding: 10px;
}

.posts {
    margin-top: 10px;
}

.errorBorder {
    border: solid 1px red;
}

