.sider {
    background: #fff;
    width: 200px;
}

.content {
    padding: 24px;
    margin: 0;
    min-height: 280px;
}
