.app-wrapper {
  margin: 0 auto;
  display: grid;
  width: 1200px;
  grid-template-areas:
    "h h"
    "n c";

  grid-template-rows: 60px 1fr;
  grid-template-columns: 2fr 10fr;
}

.app-wrapper-content {
  grid-area: c;
  background-color: cornflowerblue;
  
}
